<template>
  <div class="introducebox">
    <div class="img_header"><img :src="encnimg[num].imgurl" alt="" /></div>
    <div class="fs30">{{ contentdata[num].title }}</div>
    <div class="fs17" v-html="contentdata[num].content"></div>
    <div class="img_footer"><img src="../../img/introduce.png" alt="" /></div>
    <iframe
      v-if="cnshow"
      width="100%"
      height="100%"
      frameborder="0"
      src="https://v.qq.com/x/page/r3511z1xdx5.html"
      allowfullscreen=""
    ></iframe>
    <!-- https://v.qq.com/x/page/y35042zzowu.html?vid=y35042zzowu&tiny=0&auto=0 -->
    <!-- <iframe
      v-if="enshow"
      width="100%"
      height="100%"
      frameborder="0"
      src="https://v.qq.com/x/page/n35040cebeq.html?vid=n35040cebeq&tiny=0&auto=0"
      allowfullscreen=""
    ></iframe> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["num"]),
  },
  data() {
    return {
      enshow: false,
      cnshow: false,
      encnimg: [
        {
          imgurl: require("../../img/headerimg.png"),
        },
        {
          imgurl: require("../../img/headerenimg.png"),
        },
      ],
      contentdata: {
        0: {
          title: "羽翼苏梅项目详情",
          content: `项目名称：泰国羽翼苏梅住宅地产项目<br>
                    占地面积：19506㎡<br>
                    预计交房日期：2024年6月<br>
                    容积率：1.42<br>
                    项目楼栋：14栋（533套）<br>
                    层高：3米，共计4层<br>
                    楼高：12米<br>
                    空地率： 53.3%<br>
                    装修情况：精装<br>
                    产权年限：永久产权<br>
                    物业费：50泰铢/㎡/月<br>
                    公共区域维修基金：600泰铢/㎡<br>
                    （购房时一次性交付）<br>
                    物业配套：高端泳池、儿童泳池、餐厅&酒吧、健身房、SPA、CCTV监控、儿童乐园等<br>`,
        },
        1: {
          title: "Wing Samui Condo",
          content: `Plot size: 19506 m²<br>
                    Expected completion date: 2024.06<br>
                    Project size: 14 Buildings, 533 units<br>
                    Floors: 4-floor with elevators<br>
                    Furnishing: Fully-furnished<br>
                    Ownership: Free-hold<br>
                    Property fee: 50 baht/m²/month<br>
                    Public area maintenance fund: 600 baht/m² (one-time payment upon purchase)<br>
                    Property facilities: Swimming Pool/Tennis Court/Fitness Gym/Kids Pool/Kids Club Playground/5000+ sqm Garden/Outdoor Jogging Track<br>
                    `,
        },
      },
    };
  },
  // mounted(){
  //   this.getencn()
  // },
  created() {
    this.getencn();
    // console.log(this.enshow);
  },
  watch: {
    //通过该对象来设置侦听
    num() {
      this.getencn()
    },
  },
  methods: {
    getencn() {
      if (this.num == 0) {
        this.cnshow = true;
        this.enshow = false;
      } else {
        this.enshow = true;
        this.cnshow = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
iframe {
  height: 700px;
  width: 950px;
  margin: 50px 0;
}
.introducebox {
  width: 8rem;
  height: 100%;
  margin: 0 auto;
}
.img_header {
  height: 0.8rem;
  width: 4.26rem;
  margin: 0 auto;
  img {
    width: 100%;
  }
}
.fs30 {
  margin-top: 0.8rem;
  font-size: 0.3rem;
  text-align: center;
}
.fs17 {
  margin-top: 0.3rem;
  font-size: 0.17rem;
  text-align: center;
  font-weight: 300;
  line-height: 0.4rem;
  letter-spacing: 0.02rem;
}
.img_footer {
  width: 100%;
  margin-top: 0.3rem;
  img {
    width: 100%;
  }
}
</style>